<template>
  <div class="c-redirecting">
    <load-animation />
  </div>
</template>


<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import LoadAnimation from "@/components/animations/LoadAnimation.vue";
import { onMounted } from "vue";

export default {
  name: 'RedirectAfterGoogle',
  components: {LoadAnimation},
  props: {
    type: String
  },
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    store.dispatch('loginByGoogleAfterCallBack', {
      link: props.type,
      payload: {
        code: route.query.code,
        state: route.query.state,
      }
    })

    onMounted(() => {
      setTimeout(() => {
        router.push('/')
      }, 1000)
    })

    return {
      route,
      router
    }
  }
}
</script>

<style lang="scss">
.c-redirecting {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
