const ded = `<h2>Политика конфиденциальности</h2>
<p>Ваша конфиденциальность для нас крайне важна. На сайте dressdown.ai мы стремимся уважать вашу конфиденциальность и соблюдать применимые законы и нормы, касающиеся любой личной информации, которую мы можем собирать о вас, включая информацию, собранную через наш веб-сайт <a href="https://dressdown.ai">https://dressdown.ai</a> и другие управляемые нами сайты.</p>
<p>Если наш сайт содержит ссылки на сайты и сервисы третьих сторон, имейте в виду, что у этих сайтов и сервисов могут быть свои собственные политики конфиденциальности. После перехода по ссылке на контент третьей стороны мы рекомендуем вам ознакомиться с их политиками конфиденциальности, чтобы понять, как они собирают и используют вашу личную информацию. Эта Политика конфиденциальности не регулирует ваши действия после того, как вы покинете наш сайт.</p>
<p><strong>Дата вступления в силу:</strong> 27 июля 2023 г.<br><strong>Дата последнего обновления:</strong> 27 июля 2023 г.</p>

<h2>Собираемая информация</h2>
<p>Мы собираем информацию, которая делится на две категории: «добровольно предоставленная» и «автоматически собранная».</p>
<p><strong>Добровольно предоставленная:</strong> Это включает любую информацию, которую вы сознательно и активно предоставляете при использовании наших услуг и акций.<br><strong>Автоматически собранная:</strong> Это относится к любой информации, которая автоматически отправляется вашими устройствами при доступе к нашим продуктам и услугам.</p>

<h2>Данные устройства</h2>
<p>Когда вы посещаете наш веб-сайт или используете наши услуги, мы можем автоматически собирать информацию о вашем устройстве, такую как:</p>
<ul>
    <li>Тип устройства</li>
    <li>Операционная система</li>
</ul>
<p>Конкретные данные, которые мы собираем, могут различаться в зависимости от настроек вашего устройства и программного обеспечения. Мы рекомендуем вам ознакомиться с политиками производителя вашего устройства или поставщика программного обеспечения, чтобы понять, какая информация передается нам.</p>

<h2>Данные транзакций</h2>
<p>Данные транзакций включают информацию, которая накапливается в ходе обычной работы на нашей платформе. Это может включать записи транзакций, сохраненные файлы, пользовательские профили, аналитические данные и другие метрики, а также другую информацию, которая генерируется в процессе взаимодействия пользователей с нашими услугами.</p>

<h2>Конфиденциальность данных</h2>
<p>Мы привержены обеспечению конфиденциальности и не храним и не собираем данные пользователей или изображения, созданные на нашей платформе. Мы серьезно относимся к вашей конфиденциальности и поддерживаем строгие политики, чтобы ваши взаимодействия на dressdown.ai оставались конфиденциальными и не записывались.</p>

<h2>Сбор и использование информации</h2>
<p>Мы можем собирать личную информацию, когда вы:</p>
<ul>
    <li>Используете мобильное устройство или веб-браузер для доступа к нашему контенту</li>
    <li>Связываетесь с нами по электронной почте, через социальные сети или аналогичные технологии</li>
    <li>Упоминаете нас в социальных сетях</li>
</ul>
<p>Мы можем объединять добровольно предоставленную и автоматически собранную личную информацию с общими или исследовательскими данными из других надежных источников. Например, если вы делитесь с нами своим местоположением, мы можем объединить его с общей информацией о валюте и языке для улучшения вашего опыта на нашем сайте и в наших услугах.</p>

<h2>Безопасность вашей личной информации</h2>
<p>Мы защищаем личную информацию, которую мы собираем и обрабатываем, используя коммерчески приемлемые средства, чтобы предотвратить потерю, кражу, несанкционированный доступ, раскрытие, копирование, неправомерное использование или изменение.</p>
<p>Хотя мы стремимся защищать личную информацию, которую вы предоставляете, имейте в виду, что ни один метод электронной передачи или хранения не является полностью безопасным. Мы не можем гарантировать абсолютную безопасность.</p>
<p>Вы несете ответственность за поддержание безопасности ваших паролей и личной информации в наших услугах. Например, избегайте публикации вашей личной информации через нашу платформу.</p>

<h2>Хранение вашей личной информации</h2>
<p>Мы храним вашу личную информацию только в той мере, в какой это необходимо, обычно это только ваш адрес электронной почты для идентификации вашей учетной записи. Если личная информация больше не требуется, мы удаляем ее или анонимизируем, удаляя все идентификаторы.</p>
<p>Мы не храним медиа на наших серверах, но для предоставления результатов файлы могут проходить через наши системы и удаляются в течение 1 часа до 1 дня.</p>

<h2>Раскрытие личной информации третьим лицам</h2>
<p>Мы можем раскрывать личную информацию:</p>
<ul>
    <li>Материнской компании, дочерним компаниям или аффилированным лицам</li>
    <li>Третьим сторонам, предоставляющим услуги, таким как IT, хранение данных, хостинг, предоставление серверов, аналитика, регистрация ошибок, взыскание долгов, техническое обслуживание или решение проблем</li>
    <li>Нашим сотрудникам, подрядчикам и связанным лицам</li>
    <li>Существующим или потенциальным агентам или деловым партнерам</li>
    <li>Кредитным отчетным агентствам, судам, трибуналам и регуляторным органам, особенно если вы не оплатили предоставленные товары или услуги</li>
    <li>Судам, трибуналам, регуляторным органам и правоохранительным органам, как того требует закон, в связи с юридическими процессами или для установления, осуществления или защиты наших юридических прав</li>
    <li>Третьим лицам, включая агентов или субподрядчиков, которые помогают нам предоставлять информацию, продукты, услуги или прямой маркетинг вам</li>
    <li>Третьим лицам для сбора и обработки данных</li>
    <li>Субъекту, который приобретает все или почти все наши активы и бизнес</li>
</ul>
<p>В настоящее время к числу третьих сторон, с которыми мы работаем, относятся:</p>
<ul>
    <li>Google Analytics</li>
</ul>

<h2>Ваши права и контроль над вашей личной информацией</h2>
<p>У вас есть права, касающиеся вашей личной информации, включая:</p>
<ul>
    <li><strong>Выбор:</strong> Предоставляя личную информацию, вы понимаете, что мы будем собирать, хранить, использовать и раскрывать ее в соответствии с этой политикой. Вы можете выбрать не предоставлять личную информацию, но это может повлиять на использование нашего веб-сайта или услуг.</li>
    <li><strong>Информация от третьих сторон:</strong> Если мы получаем вашу личную информацию от третьей стороны, мы будем защищать ее в соответствии с этой политикой. Если вы являетесь третьей стороной, предоставляющей личную информацию другого человека, вы должны иметь их согласие на это.</li>
    <li><strong>Разрешение на маркетинг:</strong> Если вы ранее согласились на использование вашей личной информации для прямого маркетинга, вы можете изменить свое мнение в любое время, связавшись с нами.</li>
    <li><strong>Доступ:</strong> Вы можете запросить информацию о личных данных, которые мы храним о вас.</li>
    <li><strong>Коррекция:</strong> Если вы считаете, что какая-либо информация, которую мы храним о вас, является неточной, устаревшей, неполной, нерелевантной или вводящей в заблуждение, пожалуйста, свяжитесь с нами. Мы предпримем разумные шаги для исправления любых неточностей.</li>
    <li><strong>Нельзя дискриминировать:</strong> Мы не будем дискриминировать вас за использование ваших прав в отношении вашей личной информации. Если это не требуется для услуги, мы не будем отказывать вам в товарах или услугах, устанавливать разные цены или предоставлять разные уровни услуг на основе ваших выборов, связанных с данными.</li>
    <li><strong>Скачать личную информацию:</strong> Мы предоставляем возможность скачать личную информацию, которую вы предоставили нам. Пожалуйста, свяжитесь с нами для получения подробностей.</li>
    <li><strong>Уведомление о нарушении данных:</strong> Мы будем соблюдать применимые законы, касающиеся уведомлений о нарушении данных.</li>
    <li><strong>Жалобы:</strong> Если вы считаете, что мы нарушили соответствующий закон о защите данных, вы можете связаться с нами, чтобы подать жалобу. Мы расследуем и ответим на вашу жалобу письменно. Вы также имеете право обратиться в регулирующий орган или орган по защите данных.</li>
    <li><strong>Отказ от подписки:</strong> Чтобы отказаться от нашей рассылки по электронной почте или прекратить получение коммуникаций, вы можете связаться с нами или использовать предоставленные функции отказа. Нам может потребоваться проверить вашу личность для обработки вашего запроса.</li>
</ul>

<h2>Передача бизнеса</h2>
<p>В случае приобретения или в маловероятном случае банкротства или прекращения деятельности, ваши данные, включая личную информацию, могут быть переданы приобретателю. Вы признаете, что такие передачи могут происходить, и любая сторона, которая приобретает нас, может продолжать использовать вашу личную информацию в соответствии с этой политикой.</p>

<h2>Ограничения нашей политики</h2>
<p>Наш веб-сайт может содержать ссылки на внешние сайты, которые мы не управляем. Пожалуйста, имейте в виду, что мы не контролируем содержание и политику этих сайтов и не несем ответственности за их практику конфиденциальности.</p>

<h2>Изменения в этой политике</h2>
<p>Мы можем обновлять нашу политику конфиденциальности, чтобы отразить изменения в наших бизнес-процессах, текущих практиках или регуляторных требованиях. Если будут внесены изменения, мы опубликуем обновления по той же ссылке, которую вы сейчас используете.</p>
<p>Если это требуется законом, мы будем запрашивать ваше разрешение или предоставлять вам возможность согласиться или отказаться от новых способов использования вашей личной информации.</p>

<h2>Связь с нами</h2>
<p>По любым вопросам или опасениям, связанным с конфиденциальностью, пожалуйста, свяжитесь с нами по следующему адресу:</p>
<p><strong>Служба поддержки:</strong><br>support@dressdown.ai</p>
`


export default ded
