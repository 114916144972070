const ded = '<h2>Terms Of Service</h2>\n' +
  '<p>These Terms of Service govern your use of the website located at https://dressdown.ai and any related services provided by dressdown.ai.</p>\n' +
  '<p>By accessing https://dressdown.ai, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by dressdown.ai.</p>\n' +
  '<p>We, dressdown.ai, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication.</p>\n' +
  '<p>These Terms of Service were last updated on 25 April 2024.</p>\n' +
  '\n' +
  '<h2>Company Information</h2>\n' +
  '<p>The website dressdown.ai (hereafter referred to as the "Site") is owned and managed by DATATEH LTD. This entity, herein referred to as the "Company," "we," "us," or "our," is a company registered in England. Our primary registered office is situated at: 20 Wenlock Road, London, England, N1 7GU. You can contact us by writing to the above address or by emailing us at support@dressdown.ai.</p>\n' +
  '\n' +
  '<h2>Description of Service</h2>\n' +
  '<p>dressdown.ai is a web-based platform that leverages artificial intelligence to generate adult-themed images. Our service allows users to customize images according to specified parameters such as age, body type, and quality. The responsibility for all generated images rests solely with the user. Additionally, our platform offers an assortment of content by models available for purchase, with transactions securely processed via trusted third-party payment service providers. In line with our commitment to user privacy, we do not store or monitor any user data or generated images.</p>\n' +
  '\n' +
  '<h2>Limitations of Use</h2>\n' +
  '<p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</p>\n' +
  '<p>1. modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</p>\n' +
  '<p>2. remove any copyright or other proprietary notations from any materials and software on this website;</p>\n' +
  '<p>3. transfer the materials to another person or “mirror” the materials on any other server;</p>\n' +
  '<p>4. knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service dressdown.ai provides;</p>\n' +
  '<p>5. use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</p>\n' +
  '<p>6. use this website or its associated services in violation of any applicable laws or regulations;</p>\n' +
  '<p>7. use this website in conjunction with sending unauthorized advertising or spam;</p>\n' +
  '<p>8. harvest, collect, or gather user data without the user’s consent; or</p>\n' +
  '<p>9. use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</p>\n' +
  '\n' +
  '<h2>Intellectual Property</h2>\n' +
  '<p>The intellectual property in the materials contained in this website is owned by or licensed to dressdown.ai and is protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.</p>\n' +
  '<p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service and may be terminated by dressdown.ai at any time.</p>\n' +
  '\n' +
  '<h2>User-Generated Content</h2>\n' +
  '<p>dressdown.ai disclaims all liability for the use of content generated by users on our website. Our services allow users to generate images through a free selection process, specifying age, body type, and quality parameters. However, it is imperative to note that we do not store, monitor, or claim responsibility for these user-created images. The user who generates the content maintains absolute ownership and is solely accountable for its use or distribution, along with any potential consequences thereof.</p>\n' +
  '\n' +
  '<h2>Age Requirements and User Responsibilities</h2>\n' +
  '<p>Users must meet a minimum age requirement of 18 years or the age of majority in their jurisdiction, whichever is higher, to access and use our website. By interacting with our services, you are confirming that you meet these age requirements.</p>\n' +
  '<p>We trust our users to generate images responsibly. You are forbidden from generating images of individuals without their explicit consent or individuals who are minors. Any use of our services to infract upon others’ rights, engage in illegal activities, or carry out prohibited actions will not be tolerated.</p>\n' +
  '\n' +
  '<h2>Purchases and Payment Options</h2>\n' +
  '<p>At dressdown.ai, we offer distinct payment plans designed to enhance user experience, with each plan providing specific features and credits:</p>\n' +
  '<p>- <strong>15 EUR</strong>: 30 generations.</p>\n' +
  '<p>- <strong>25 EUR</strong>: 60 generations.</p>\n' +
  '<p>- <strong>35 EUR</strong>: 90 generations.</p>\n' +
  '<p>- <strong>45 EUR</strong>: 120 generations.</p>\n' +
  '<p>- <strong>99 EUR</strong>: 270 generations.</p>\n' +
  '<p>All plans include fast generation and HD quality images. Payments are made as a single transaction, with no automatic renewal. Users should familiarize themselves with their chosen plan’s specific features and limitations.</p>\n' +
  '\n' +
  '<h2>Liability</h2>\n' +
  '<p>Our website and the materials on our website are provided on an \'as is\' basis. To the extent permitted by law, dressdown.ai makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.</p>\n' +
  '<p>In no event shall dressdown.ai or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if dressdown.ai or an authorized representative has been notified, orally or in writing, of the possibility of such damage.</p>\n' +
  '<p>In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use, and/or loss or corruption of data, whether under the statute, contract, equity, tort (including negligence), indemnity, or otherwise.</p>\n' +
  '<p>Because some jurisdictions do not allow limitations on implied warranties or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>\n' +
  '\n' +
  '<h2>Zero Tolerance for Explicit Content Involving Minors</h2>\n' +
  '<p>We firmly oppose any explicit content involving underage individuals. We proactively monitor for any prohibited content using advanced automated systems to establish a secure environment for all our users. Any accounts found generating or utilizing explicit content of underage individuals will be immediately banned and reported to the relevant authorities in the user’s country.</p>\n' +
  '\n' +
  '<h2>Accuracy of Materials</h2>\n' +
  '<p>The materials appearing on our website are not comprehensive and are for general information purposes only. dressdown.ai does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website. You should not rely solely on the said materials to make decisions; we advise you to consult other, more complete sources of information. You are solely responsible for strictly relying on our website’s materials. We have the right to change the information on our website without prior notice, and users are responsible for monitoring said changes. Any historical information present on our website is provided for reference only.</p>\n' +
  '\n' +
  '<h2>Additional Tools</h2>\n' +
  '<p>Please note that our website may include third-party tools which are outside of our control. The access to said tools is provided on an \'as is\' basis with no warranties, expressed or implied, and without any endorsement. We are not liable for the use of the third-party tools provided on our website. You are solely responsible for any risks associated with using said tools; we recommend reading the relevant third-party provider’s Terms of Service. dressdown.ai has the right to offer new third-party tools in the future.</p>\n' +
  '\n' +
  '<h2>Links</h2>\n' +
  '<p>dressdown.ai has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by dressdown.ai of the site. Use of any such linked site is at your own risk, and we strongly advise you make your own investigations with respect to the suitability of those sites.</p>\n' +
  '\n' +
  '<h2>Indemnification</h2>\n' +
  '<p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the website; (3) breach of these Terms of Service; (4) any breach of your representations and warranties set forth in these Terms of Service; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the website with whom you connected via the website. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>\n' +
  '\n' +
  '<h2>Termination</h2>\n' +
  '<p>We may suspend or terminate your account and your access to the website and services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms of Service. Upon termination, your right to use the services will cease immediately.</p>\n' +
  '<p>If you wish to terminate your account, you may simply discontinue using the website.</p>\n' +
  '<p>All provisions of the Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>\n' +
  '\n' +
  '<h2>Severability</h2>\n' +
  '<p>If any provision of these Terms of Service is found to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect.</p>\n' +
  '\n' +
  '<h2>Changes</h2>\n' +
  '<p>dressdown.ai reserves the right, at our sole discretion, to modify or replace these Terms of Service at any time. If a revision is material, we will provide at least 30 days\' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>\n' +
  '<p>By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use dressdown.ai.</p>\n' +
  '\n' +
  '<h2>Entire Agreement</h2>\n' +
  '<p>The Terms of Service, together with our Privacy Policy and any other legal notices or policies posted by us on the website constitute the entire agreement between you and dressdown.ai regarding your use of the website.</p>\n' +
  '\n' +
  '<h2>Governing Law</h2>\n' +
  '<p>These Terms of Service are governed by and construed in accordance with the laws of England. You irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>'
export default ded
