import { apiInstance } from '@/core/axios';
export default {
  state: {
    balance: 0,
    redirectLink: '',
    userEmail: ''
  },
  getters: {
    getRedirectLink(state) {
      return state.redirectLink
    },
    getMyBalance (state) {
      return state.balance
    }
  },
  mutations: {
    setRedirectLink (state, link) {
      state.redirectLink = link
    },
    setEmail (state, email) {
      state.userEmail = email
    },
    setMyBalance (state, payload) {
      state.balance = payload
    }
  },
  actions: {
    async setBalance({ rootState, dispatch, commit }, formData) {
      try {
        const response = await apiInstance.post('/api/payments/initiate/', formData, {
          headers: {
            'Authorization': `Bearer ${rootState.auth.token}`,
            'Content-Type': 'application/json'
          }
        })

        commit('setRedirectLink', response.data.checkout)

        dispatch('handleToast', {
          text: 'Successful! Redirecting...',
          type: 'SUCCESS'
        })
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async getCash() {
      try {
        await apiInstance.get('https://finaltestundress-de54dd54b61b.herokuapp.com/api/payments/initiate/')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
