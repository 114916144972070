const ded = '`<h2>適正使用ポリシー</h2>\n' +
  '<p>この適正使用ポリシー（以下「ポリシー」）は、dressdown.ai が提供する製品、サービス、および技術（以下「サービス」）の使用を規定します。その目的は、dressdown.ai、その顧客、および広範なインターネットコミュニティを不倫理的、不適切、または違法な活動から保護することです。</p>\n' +
  '<p>このポリシーに違反した場合、サービスの停止またはアカウントの終了が行われることがあります。重大な場合には、違反を関連する当局に報告する法的義務が生じることがあります。</p>\n' +
  '<p>このポリシーは、2024年6月22日に最終確認されました。</p>\n' +
  '\n' +
  '<h2>公正な使用</h2>\n' +
  '<p>私たちは、私たちの提供スケジュールに従った標準的なビジネス用途を前提にサービスを提供します。使用が過度と見なされる場合、追加料金が発生することや、容量の制限が行われることがあります。</p>\n' +
  '<p>私たちは、すべての形態の悪用、差別、権利の侵害、および個人、グループ、またはリソースに対する害や不利益を引き起こす行動に反対します。私たちは、顧客およびそのユーザー（以下「エンドユーザー」）がこの基準に従ってサービスを利用することを期待しています。</p>\n' +
  '\n' +
  '<h2>顧客の責任</h2>\n' +
  '<p>顧客は、自分自身およびサービスの使用を許可したすべての人の行動に対して責任を負います。この責任には、合理的なセキュリティ対策を講じなかった結果としての不正使用も含まれます。</p>\n' +
  '<p>サービスを使用することにより、顧客は、自分のアカウントを通じてサービスを利用するすべての人に対してこのポリシーを適用することに同意します。顧客やエンドユーザーの行動に関する苦情は、指定されたアカウント連絡先に向けられます。</p>\n' +
  '<p>顧客、エンドユーザー、または顧客を通じてサービスを利用している人がこのポリシーに違反した場合、私たちは、事前通知なしに関連するサービスまたはアカウント自体を停止または終了する権利を留保します。また、必要と判断されるその他の措置を講じることがあります。法律で許可されている範囲内で、ポリシー違反によるサービス中断についてのクレジットは提供されません。</p>\n' +
  '\n' +
  '<h2>禁止される活動</h2>\n' +
  '\n' +
  '<h3>著作権侵害および不正アクセス</h3>\n' +
  '<p>私たちのサービスを使用して、適用される法律に違反する素材を送信、配布、または保存することは禁止されています。これには以下が含まれます：</p>\n' +
  '<p>適切な許可なしに著作権、商標、営業秘密、またはその他の知的財産権で保護された素材。</p>\n' +
  '<p>猥褻、誹謗、中傷、違法な脅迫、または輸出管理法に違反する素材。</p>\n' +
  '<p>顧客は、サービスを通じて入力、アップロード、配布、または作成するすべての素材に対して責任を負い、含まれる作品について法的な許可を取得する必要があります。</p>\n' +
  '\n' +
  '<h3>スパムおよび無断メッセージ</h3>\n' +
  '<p>私たちのサービスを使用して、適用される法律に違反してスパム（無断の一斉送信または商業メッセージ）を送信することは禁止されています。これには以下が含まれます：</p>\n' +
  '<p>スパムを送信すること、スパムを通じて顧客を勧誘すること、またはそのようなスパムに対する返信を収集すること。</p>\n' +
  '<p>所有者の明示的な許可なしに確認されていないメーリングリストまたは電話番号リスト（「メッセージングリスト」）を運営すること。</p>\n' +
  '<p>購入したメールアドレスや電話番号リストをスパムまたは確認されていないメッセージの目的で使用すること。</p>\n' +
  '<p>サービス上でホストされているすべてのメッセージングリストは「確認済みオプトイン」でなければならず、リストの期間中に許可の確認が可能である必要があります。</p>\n' +
  '\n' +
  '<h3>非倫理的、搾取的、または悪意のある活動</h3>\n' +
  '<p>私たちのサービスを使用して以下のことを行うことは禁止されています：</p>\n' +
  '<p>このポリシーまたは他のポリシーに違反するソフトウェアやプログラム、スパムツールや攻撃ツールなどを宣伝または送信すること。</p>\n' +
  '<p>認可なしにアカウントやリソースにアクセスすること（例：ハッキング、クラッキング）。</p>\n' +
  '<p>私たちのシステムにウイルスや悪意のあるコードを導入すること。</p>\n' +
  '<p>個人やグループを嫌がらせや害を与える活動を行うこと、サービス拒否攻撃やヘイトスピーチを含む。</p>\n' +
  '<p>その他の非倫理的な活動には以下が含まれます：</p>\n' +
  '<p>サービスの支払いを回避しようとすること。</p>\n' +
  '<p>他の人によるサービスの使用を妨害すること。</p>\n' +
  '<p>暴力を扇動するまたは有害な行為を描写するコンテンツを公開すること。</p>\n' +
  '<p>消費者保護法やプライバシー権を侵害すること。</p>\n' +
  '<p>私たちのサービスは、違法賭博、テロリズム、麻薬密輸、武器拡散、または関連活動に関与している、または関与が疑われるいかなる団体によっても使用することはできません。</p>\n' +
  '\n' +
  '<h3>dressdown.ai の資産の不正使用</h3>\n' +
  '<p>dressdown.ai を偽装することや、dressdown.ai との関係を詐欺目的で誤って表示することは厳格に禁止されています。これには、ブランド、製品、またはその他の資産を無許可で使用してサービスやカスタマー、顧客、またはユーザーの信頼を得ることが含まれます。</p>\n' +
  '\n' +
  '<h2>ポリシーの変更</h2>\n' +
  '<p>私たちは、このポリシーをいつでも変更する権利を留保します。変更がある場合は、ウェブサイトに公開され、更新されたポリシーは以下のいずれか早い日付から有効となります：</p>\n' +
  '<p>改訂されたポリシーの公開後にサービスを利用する日、または</p>\n' +
  '<p>改訂されたポリシーがウェブサイトに公開されてから30日後。</p>\n' +
  '<p>ポリシーの変更について最新情報を把握するために、定期的にこのポリシーを確認してください。</p>\n' +
  '`\n'

export default ded
