const ded = `<h2>شروط الخدمة</h2>\\n' +
  '<p>تحكم شروط الخدمة هذه استخدامك للموقع الإلكتروني الموجود على https://dressdown.ai وأي خدمات ذات صلة مقدمة من dressdown.ai.</p>\\n' +
  '<p>من خلال الوصول إلى https://dressdown.ai، فإنك توافق على الالتزام بشروط الخدمة هذه والامتثال لجميع القوانين واللوائح المعمول بها. إذا كنت لا توافق على شروط الخدمة هذه، فأنت ممنوع من استخدام أو الوصول إلى هذا الموقع أو استخدام أي خدمات أخرى مقدمة من dressdown.ai.</p>\\n' +
  '<p>نحن، dressdown.ai، نحتفظ بالحق في مراجعة وتعديل أي من شروط الخدمة هذه وفقًا لتقديرنا الخاص. عند القيام بذلك، سنقوم بتحديث هذه الصفحة. ستصبح أي تغييرات على شروط الخدمة سارية المفعول فورًا من تاريخ النشر.</p>\\n' +
  '<p>تم تحديث شروط الخدمة هذه آخر مرة في 25 أبريل 2024.</p>\\n' +
  '\\n' +
  '<h2>معلومات الشركة</h2>\\n' +
  '<p>الموقع الإلكتروني dressdown.ai (يشار إليه فيما بعد بـ "الموقع") مملوك ومدار من قبل شركة DATATEH LTD. هذه الكيان، الذي يُشار إليه هنا باسم "الشركة"، "نحن"، "لنا"، أو "خاصتنا"، هو شركة مسجلة في إنجلترا. يقع مكتبنا المسجل الأساسي في: 20 Wenlock Road, London, England, N1 7GU. يمكنك الاتصال بنا عن طريق الكتابة إلى العنوان المذكور أعلاه أو عن طريق إرسال بريد إلكتروني إلينا على support@dressdown.ai.</p>\\n' +
  '\\n' +
  '<h2>وصف الخدمة</h2>\\n' +
  '<p>dressdown.ai هي منصة ويب تعتمد على الذكاء الاصطناعي لتوليد صور مخصصة ذات طابع بالغ. تتيح خدمتنا للمستخدمين تخصيص الصور وفقًا للمعايير المحددة مثل العمر ونوع الجسم والجودة. تقع مسؤولية جميع الصور المولدة بشكل كامل على عاتق المستخدم. بالإضافة إلى ذلك، يوفر منصتنا مجموعة متنوعة من المحتويات من خلال نماذج متاحة للشراء، مع معالجة المعاملات بأمان عبر مزودي خدمات الدفع الموثوق بهم. تماشيًا مع التزامنا بخصوصية المستخدم، فإننا لا نخزن أو نراقب أي بيانات مستخدم أو صور مولدة.</p>\\n' +
  '\\n' +
  '<h2>القيود على الاستخدام</h2>\\n' +
  '<p>باستخدام هذا الموقع، فإنك تتعهد نيابةً عن نفسك، ومستخدميك، والأطراف الأخرى التي تمثلها بعدم:</p>\\n' +
  '<p>1. تعديل، نسخ، إعداد أعمال مشتقة من، فك أو عكس هندسة أي مواد وبرامج موجودة على هذا الموقع الإلكتروني؛</p>\\n' +
  '<p>2. إزالة أي إشعارات حقوق الطبع والنشر أو إشعارات الملكية الأخرى من أي مواد وبرامج على هذا الموقع الإلكتروني؛</p>\\n' +
  '<p>3. نقل المواد إلى شخص آخر أو "مرايا" المواد على أي خادم آخر؛</p>\\n' +
  '<p>4. استخدام هذا الموقع أو أي من خدماته المرتبطة بطريقة تسيء أو تعطل شبكاتنا أو أي خدمة أخرى يقدمها dressdown.ai عن عمد أو بإهمال؛</p>\\n' +
  '<p>5. استخدام هذا الموقع أو خدماته المرتبطة لنقل أو نشر أي مواد تحرشية أو غير لائقة أو فاحشة أو احتيالية أو غير قانونية؛</p>\\n' +
  '<p>6. استخدام هذا الموقع أو خدماته المرتبطة في انتهاك لأي قوانين أو لوائح معمول بها؛</p>\\n' +
  '<p>7. استخدام هذا الموقع بالاشتراك مع إرسال إعلانات غير مصرح بها أو بريد عشوائي؛</p>\\n' +
  '<p>8. جمع أو جمع بيانات المستخدم بدون موافقة المستخدم؛ أو</p>\\n' +
  '<p>9. استخدام هذا الموقع أو خدماته المرتبطة بطريقة قد تنتهك الخصوصية أو حقوق الملكية الفكرية أو حقوق الأطراف الثالثة الأخرى.</p>\\n' +
  '\\n' +
  '<h2>الملكية الفكرية</h2>\\n' +
  '<p>الملكية الفكرية في المواد الموجودة على هذا الموقع مملوكة من قبل أو مرخصة لـ dressdown.ai وهي محمية بموجب قانون حقوق الطبع والنشر والعلامات التجارية المعمول به. نمنح مستخدمينا إذنًا بتنزيل نسخة واحدة من المواد للاستخدام الشخصي غير التجاري المؤقت.</p>\\n' +
  '<p>يشكل هذا ترخيصًا وليس نقلًا للملكية. يتم إنهاء هذا الترخيص تلقائيًا إذا انتهكت أيًا من هذه القيود أو شروط الخدمة ويمكن إنهاؤها من قبل dressdown.ai في أي وقت.</p>\\n' +
  '\\n' +
  '<h2>المحتوى الذي ينشئه المستخدم</h2>\\n' +
  '<p>تخلي dressdown.ai عن أي مسؤولية عن استخدام المحتوى الذي ينشئه المستخدمون على موقعنا الإلكتروني. تتيح خدماتنا للمستخدمين إنشاء صور من خلال عملية اختيار حرة، وتحديد معايير العمر، ونوع الجسم، والجودة. ومع ذلك، من المهم ملاحظة أننا لا نخزن أو نراقب أو ندعي المسؤولية عن هذه الصور التي ينشئها المستخدم. يحتفظ المستخدم الذي ينشئ المحتوى بالملكية المطلقة ويكون مسؤولًا بشكل كامل عن استخدامه أو توزيعه، بالإضافة إلى أي تبعات محتملة لذلك.</p>\\n' +
  '\\n' +
  '<h2>متطلبات العمر ومسؤوليات المستخدم</h2>\\n' +
  '<p>يجب أن يكون المستخدمون قد بلغوا سن 18 عامًا على الأقل أو سن الرشد في نطاقهم القضائي، أيهما أكبر، للوصول إلى موقعنا الإلكتروني واستخدامه. من خلال التفاعل مع خدماتنا، فإنك تؤكد أنك تستوفي متطلبات العمر هذه.</p>\\n' +
  '<p>نثق في مستخدمينا لتوليد الصور بمسؤولية. يُمنع منعًا باتًا توليد صور لأشخاص دون موافقتهم الصريحة أو لأشخاص قاصرين. لن يتم التسامح مع أي استخدام لخدماتنا لانتهاك حقوق الآخرين أو الانخراط في أنشطة غير قانونية أو القيام بأعمال محظورة.</p>\\n' +
  '\\n' +
  '<h2>الشراء وخيارات الدفع</h2>\\n' +
  '<p>في dressdown.ai، نقدم خطط دفع مختلفة مصممة لتعزيز تجربة المستخدم، مع تقديم كل خطة ميزات وائتمانات محددة:</p>\\n' +
  '<p>- <strong>15 يورو</strong>: 30 توليدًا.</p>\\n' +
  '<p>- <strong>25 يورو</strong>: 60 توليدًا.</p>\\n' +
  '<p>- <strong>35 يورو</strong>: 90 توليدًا.</p>\\n' +
  '<p>- <strong>45 يورو</strong>: 120 توليدًا.</p>\\n' +
  '<p>- <strong>99 يورو</strong>: 270 توليدًا.</p>\\n' +
  '<p>تشمل جميع الخطط توليد سريع وصور عالية الدقة. يتم الدفع كمعاملة واحدة، دون تجديد تلقائي. يجب على المستخدمين التعرف على الميزات والقيود الخاصة بالخطة التي يختارونها.</p>\\n' +
  '\\n' +
  '<h2>المسؤولية</h2>\\n' +
  '<p>يتم تقديم موقعنا الإلكتروني والمواد الموجودة عليه على أساس "كما هي". إلى الحد الذي يسمح به القانون، لا تقدم dressdown.ai أي ضمانات، صريحة أو ضمنية، وتتنازل بموجب هذا عن جميع الضمانات الأخرى، بما في ذلك على سبيل المثال لا الحصر، الضمانات الضمنية أو شروط القابلية للتسويق، الملاءمة لغرض معين، أو عدم انتهاك الملكية الفكرية، أو غيرها من الانتهاكات للحقوق.</p>\\n' +
  '<p>لن تكون dressdown.ai أو موردوها تحت أي ظرف من الظروف مسؤولين عن أي خسارة ناتجة أو تتكبدها أنت أو أي طرف ثالث نتيجة لاستخدامك أو عدم قدرتك على استخدام هذا الموقع أو المواد الموجودة عليه، حتى إذا تم إبلاغ dressdown.ai أو ممثلها المعتمد، شفهيًا أو كتابيًا، بإمكانية حدوث مثل هذا الضرر.</p>\\n' +
  '<p>في سياق هذه الاتفاقية، يشمل "الخسارة الناتجة" أي خسارة ناتجة، خسارة غير مباشرة، خسارة فعلية أو متوقعة للأرباح، خسارة الفائدة، خسارة الإيرادات، خسارة العمل، خسارة حسن النية، خسارة الفرصة، خسارة المدخرات، خسارة السمعة، خسارة الاستخدام، و/أو خسارة أو فساد البيانات، سواء كانت بموجب القانون، العقد، العدالة، الضرر (بما في ذلك الإهمال)، التعويض، أو غير ذلك.</p>\\n' +
  '<p>نظرًا لأن بعض السلطات القضائية لا تسمح بفرض قيود على الضمانات الضمنية أو حدود المسؤولية عن الأضرار الناتجة أو العرضية، فقد لا تنطبق عليك هذه القيود.</p>\\n' +
  '\\n' +
  '<h2>عدم التسامح مطلقًا مع المحتوى الصريح المتعلق بالقاصرين</h2>\\n' +
  '<p>نحن نعارض بشدة أي محتوى صريح يتعلق بالأفراد القاصرين. نقوم بمراقبة استباقية لأي محتوى محظور باستخدام أنظمة آلية متقدمة لضمان بيئة آمنة لجميع مستخدمينا. سيتم حظر أي حسابات يتم العثور عليها لتوليد أو استخدام محتوى صريح للأفراد القاصرين على الفور والإبلاغ عنها إلى السلطات المختصة في بلد المستخدم.</p>\\n' +
  '\\n' +
  '<h2>دقة المواد</h2>\\n' +
  '<p>المواد التي تظهر على موقعنا الإلكتروني ليست شاملة وتستخدم لأغراض المعلومات العامة فقط. لا تضمن dressdown.ai أو تقدم أي تمثيلات بشأن دقة النتائج المحتملة أو موثوقية استخدام المواد على هذا الموقع الإلكتروني، أو فيما يتعلق بتلك المواد أو على أي موارد مرتبطة بهذا الموقع. لا يجب أن تعتمد على المواد المذكورة لاتخاذ قرارات؛ ننصحك بمراجعة مصادر أخرى، أكثر اكتمالاً للمعلومات. أنت وحدك المسؤول عن الاعتماد بشكل صارم على المواد الموجودة على موقعنا الإلكتروني. لدينا الحق في تغيير المعلومات الموجودة على موقعنا الإلكتروني دون إشعار مسبق، ويكون المستخدمون مسؤولين عن مراقبة هذه التغييرات. يتم تقديم أي معلومات تاريخية موجودة على موقعنا لأغراض مرجعية فقط.</p>\\n' +
  '\\n' +
  '<h2>أدوات إضافية</h2>\\n' +
  '<p>يرجى ملاحظة أن موقعنا الإلكتروني قد يتضمن أدوات تابعة لطرف ثالث وهي خارج سيطرتنا. يتم توفير الوصول إلى هذه الأدوات على أساس "كما هي" دون ضمانات، صريحة أو ضمنية، ودون أي تأييد. نحن غير مسؤولين عن استخدام الأدوات التابعة لطرف ثالث الموجودة على موقعنا. أنت وحدك المسؤول عن أي مخاطر مرتبطة باستخدام هذه الأدوات؛ نوصي بقراءة شروط الخدمة الخاصة بمزود الطرف الثالث المعني. تحتفظ dressdown.ai بالحق في تقديم أدوات جديدة تابعة لطرف ثالث في المستقبل.</p>\\n' +
  '\\n' +
  '<h2>الروابط</h2>\\n' +
  '<p>لم تقم dressdown.ai بمراجعة جميع المواقع المرتبطة بموقعها الإلكتروني وليست مسؤولة عن محتويات أي من هذه المواقع المرتبطة. لا يعني تضمين أي رابط تأييد dressdown.ai أو الموافقة على الموقع أو السيطرة عليه. استخدام أي موقع مرتبط يتم على مسؤوليتك الخاصة، وننصحك بشدة بإجراء التحقيقات الخاصة بك فيما يتعلق بمدى ملاءمة هذه المواقع.</p>\\n' +
  '\\n' +
  '<h2>التعويض</h2>\\n' +
  '<p>أنت توافق على الدفاع عننا وتعويضنا وحمايتنا من أي خسارة أو ضرر أو مسؤولية أو مطالبة أو طلب، بما في ذلك أتعاب المحاماة المعقولة والنفقات، التي يقدمها أي طرف ثالث بسبب أو ناتج عن: (1) مساهماتك؛ (2) استخدام الموقع؛ (3) انتهاكك لشروط الخدمة هذه؛ (4) أي خرق لتمثيلاتك وضماناتك المنصوص عليها في شروط الخدمة هذه؛ (5) انتهاكك لحقوق طرف ثالث، بما في ذلك على سبيل المثال لا الحصر، حقوق الملكية الفكرية؛ أو (6) أي عمل ضار مباشر تجاه أي مستخدم آخر للموقع قمت بالاتصال به عبر الموقع. على الرغم من ما سبق، نحتفظ بالحق، على نفقتك الخاصة، في تولي الدفاع الحصري والسيطرة على أي مسألة تطالب بتعويضنا، وتوافق على التعاون، على نفقتك الخاصة، في دفاعنا عن هذه المطالبات. سنبذل جهودًا معقولة لإعلامك بأي مطالبة أو إجراء أو إجراء يكون خاضعًا لهذا التعويض عند علمنا به.</p>\\n' +
  '\\n' +
  '<h2>الإنهاء</h2>\\n' +
  '<p>يجوز لنا تعليق أو إنهاء حسابك ووصولك إلى الموقع والخدمات على الفور، دون إشعار مسبق أو مسؤولية، لأي سبب كان، بما في ذلك، على سبيل المثال لا الحصر، إذا انتهكت شروط الخدمة. عند الإنهاء، يتوقف حقك في استخدام الخدمات فورًا.</p>\\n' +
  '<p>إذا كنت ترغب في إنهاء حسابك، يمكنك ببساطة التوقف عن استخدام الموقع.</p>\\n' +
  '<p>ستظل جميع أحكام شروط الخدمة التي يجب بطبيعتها أن تستمر بعد الإنهاء سارية بعد الإنهاء، بما في ذلك على سبيل المثال لا الحصر، أحكام الملكية، إخلاء الضمانات، التعويض، وحدود المسؤولية.</p>\\n' +
  '\\n' +
  '<h2>القابلية للفصل</h2>\\n' +
  '<p>إذا تم العثور على أي حكم من أحكام شروط الخدمة هذه غير قابل للتنفيذ أو غير صالح، فسيتم تعديل هذا الحكم وتفسيره لتحقيق أهداف هذا الحكم إلى أقصى حد ممكن بموجب القانون المعمول به، وستظل الأحكام المتبقية سارية بالكامل وفعالة.</p>\\n' +
  '\\n' +
  '<h2>التغييرات</h2>\\n' +
  '<p>تحتفظ dressdown.ai بالحق، وفقًا لتقديرنا الخاص، في تعديل أو استبدال شروط الخدمة هذه في أي وقت. إذا كان التعديل جوهريًا، فسنقدم إشعارًا لمدة 30 يومًا على الأقل قبل أن تصبح الشروط الجديدة سارية المفعول. سيتم تحديد ما يشكل تغييرًا جوهريًا وفقًا لتقديرنا الخاص.</p>\\n' +
  '<p>من خلال الاستمرار في الوصول إلى أو استخدام خدماتنا بعد أن تصبح هذه التعديلات سارية المفعول، فإنك توافق على الالتزام بالشروط المعدلة. إذا كنت لا توافق على الشروط الجديدة، فأنت لم تعد مخولًا لاستخدام dressdown.ai.</p>\\n' +
  '\\n' +
  '<h2>الاتفاق الكامل</h2>\\n' +
  '<p>تشكل شروط الخدمة، جنبًا إلى جنب مع سياسة الخصوصية الخاصة بنا وأي إشعارات قانونية أو سياسات أخرى منشورة من قبلنا على الموقع، الاتفاقية الكاملة بينك وبين dressdown.ai فيما يتعلق باستخدامك للموقع.</p>\\n' +
  '\\n' +
  '<h2>القانون الحاكم</h2>\\n' +
  '<p>تخضع شروط الخدمة هذه وتفسر وفقًا لقوانين إنجلترا. أنت توافق بشكل غير قابل للإلغاء على الاختصاص القضائي الحصري للمحاكم في ذلك الموقع.</p>\\n`
export default ded
