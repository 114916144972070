<template>
  <div @click="addCash" class="c-profile-mini">
    <div class="c-profile-mini_wrapper">
      <img src="@/assets/small-logo.png" width="28" height="28" alt="profile user icon">
    </div>
    <div class="c-profile-mini_balanced">
      <div class="c-profile-mini_balanced-count">
        {{ myBalance }}
      </div>
      <div class="c-profile-mini_balanced-add">
        <img src="@/assets/icons/plus.svg" width="32" height="32" alt="plus add icon">
      </div>
    </div>
<!--    <div v-show="isOpen" class="c-profile-mini_popup">-->
<!--      <div @click="addCash" class="c-profile-mini_popup-item">Add cash</div>-->
<!--      <div @click="handleLogout" class="c-profile-mini_popup-item c-profile-mini_popup-item&#45;&#45;logout">Logout</div>-->
<!--    </div>-->
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: 'ProfileMini',
  setup() {
    const store = useStore()
    const isOpen = ref(false)
    const openPopup = () => {
      isOpen.value = !isOpen.value
    }
    const closePopup = () => {
      isOpen.value = false
    }
    const addCash = () => {
      store.commit('addModal', 'Cash')
    }

    const handleLogout = () => {
      store.dispatch('logout')
    }

    const myBalance = computed(() => {
      return store.getters.getMyBalance
    })

    return {
      closePopup,
      handleLogout,
      addCash,
      openPopup,
      myBalance,
      isOpen
    }
  }
}
</script>

<style lang="scss">
.c-profile-mini {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  cursor: pointer;

  @media screen and (max-width: 450px) {
    display: flex;
    justify-content: space-between;
    min-width: 108px;
    padding: 0 5px;
    gap: 12px;
  }

  &:hover {
    border-color: #b4f12f;
  }

  &_wrapper {
    img {
      margin-bottom: -5px;
    }
    @media screen and (max-width: 450px) {
      //display: none;
    }
  }

  &_balanced {
    display: flex;
    align-items: center;
    margin-left: 10px;
    @media screen and (max-width: 450px) {
      justify-content: center;
      width: 100%;
      margin-left: 0;
    }
    &-add {
      margin-left: 10px;
      img  {
        display: block;
      }
    }
  }
  &_popup {
    position: absolute;
    left: 0;
    top: 55px;
    width: 100%;
    background-color: #333333;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    @media screen and (max-width: 450px) {
      top: 40px;
    }
    &-item {
      color: #FFFFFF;
      padding: 8px 15px;

      &:hover {
        background-color: #b4f12f;
        color: #000000;
      }

      &--logout {
        color: red;
      }
    }
  }
}
</style>
