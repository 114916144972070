const ded = '`<h2>Политика допустимого использования</h2>\n' +
  '<p>Эта Политика допустимого использования (далее «Политика») регулирует использование продуктов, услуг и технологий (в совокупности именуемых «Услуги»), предоставляемых dressdown.ai в рамках любого активного соглашения. Ее цель — защитить dressdown.ai, его клиентов и широкое интернет-сообщество от неэтичных, безответственных и незаконных действий.</p>\n' +
  '<p>Нарушения этой Политики могут привести к приостановке обслуживания или закрытию аккаунта. В серьезных случаях мы можем быть обязаны сообщить о нарушениях соответствующим органам.</p>\n' +
  '<p>Эта Политика была в последний раз пересмотрена 22 июня 2024 года.</p>\n' +
  '\n' +
  '<h2>Честное использование</h2>\n' +
  '<p>Мы предоставляем наши Услуги с ожиданием стандартного, обычного использования, как указано в нашем графике предложений. Если ваше использование будет признано чрезмерным, могут применяться дополнительные сборы или ограничение пропускной способности.</p>\n' +
  '<p>Мы против всех форм злоупотреблений, дискриминации, нарушения прав и любых действий, причиняющих вред или неудобства отдельным лицам, группам или ресурсам. Мы ожидаем, что наши клиенты и их пользователи («Конечные пользователи») будут использовать наши Услуги в соответствии с этим стандартом.</p>\n' +
  '\n' +
  '<h2>Ответственность клиентов</h2>\n' +
  '<p>Клиенты несут ответственность за свои действия и действия всех, кому они разрешают использовать наши Услуги. Эта ответственность распространяется на несанкционированное использование, которое происходит из-за отсутствия разумных мер безопасности.</p>\n' +
  '<p>Используя наши Услуги, клиенты соглашаются соблюдать эту Политику для всех, кто использует Услуги через их аккаунт. Жалобы на действия клиента или Конечного пользователя будут направляться на указанный контактный адрес аккаунта.</p>\n' +
  '<p>В случаях, когда клиент, его Конечный пользователь или кто-либо, использующий наши Услуги через клиента, нарушает эту Политику, мы оставляем за собой право приостановить или закрыть связанные Услуги или сам аккаунт, или предпринять любые другие необходимые действия без предварительного уведомления. В пределах, разрешенных законом, кредиты не будут предоставляться за прерывание услуг в результате нарушения Политики.</p>\n' +
  '\n' +
  '<h2>Запрещенные действия</h2>\n' +
  '\n' +
  '<h3>Нарушение авторских прав и несанкционированный доступ</h3>\n' +
  '<p>Наши Услуги не должны использоваться для передачи, распространения или хранения материалов, которые нарушают применимые законы. Это включает в себя:</p>\n' +
  '<p>Материалы, защищенные авторским правом, товарным знаком, коммерческой тайной или другими правами интеллектуальной собственности без надлежащего разрешения.</p>\n' +
  '<p>Порнографические, клеветнические, незаконные угрозы или материалы, нарушающие законы о контроле за экспортом.</p>\n' +
  '<p>Клиенты несут ответственность за все материалы, которые они вводят, загружают, распространяют или создают через наши Услуги, и должны получать юридическое разрешение на любые включенные произведения.</p>\n' +
  '\n' +
  '<h3>Спам и нежелательные сообщения</h3>\n' +
  '<p>Наши Услуги не должны использоваться для отправки нежелательных массовых или коммерческих сообщений, нарушающих применимые законы («спам»). Это включает в себя:</p>\n' +
  '<p>Отправку спама, привлечение клиентов с помощью спама, отправленного через другие службы, или сбор ответов на такой спам.</p>\n' +
  '<p>Запуск неподтвержденных списков рассылки или телефонных номеров («списков рассылки») без явного разрешения от владельцев.</p>\n' +
  '<p>Использование купленных списков электронных адресов или телефонных номеров для спама или неподтвержденных сообщений.</p>\n' +
  '<p>Все списки рассылки, размещенные на наших Услугах, должны быть «подтвержденными подписками», с проверкой разрешения на протяжении всего времени действия списка.</p>\n' +
  '\n' +
  '<h3>Неэтичные, эксплуататорские и вредоносные действия</h3>\n' +
  '<p>Наши Услуги не должны использоваться для:</p>\n' +
  '<p>Рекламирования или передачи программного обеспечения или программ, предназначенных для нарушения этой Политики или других политик, включая инструменты для спама или инициирования атак.</p>\n' +
  '<p>Доступа к аккаунтам или ресурсам без разрешения (например, взлом, взлом паролей).</p>\n' +
  '<p>Введения вирусов или вредоносного кода в наши системы.</p>\n' +
  '<p>Участия в действиях, направленных на преследование или причинение вреда отдельным лицам или группам, включая атаки отказа в обслуживании и ненавистническую речь.</p>\n' +
  '<p>Другие неэтичные действия включают:</p>\n' +
  '<p>Попытки избежать оплаты за услуги.</p>\n' +
  '<p>Вмешательство в использование наших Услуг другими пользователями.</p>\n' +
  '<p>Публикацию контента, который подстрекает к насилию или изображает вредные действия.</p>\n' +
  '<p>Нарушение законов о защите потребителей или прав на частную жизнь.</p>\n' +
  '<p>Наши Услуги не могут использоваться любой организацией, связанной с незаконными азартными играми, терроризмом, наркоторговлей, распространением оружия или подобной деятельностью.</p>\n' +
  '\n' +
  '<h3>Несанкционированное использование собственности dressdown.ai</h3>\n' +
  '<p>Имитация dressdown.ai или ложное представление о связи с dressdown.ai в мошеннических целях строго запрещены. Это включает в себя несанкционированное использование нашего бренда, продуктов или другой собственности для получения услуг, клиентов, покровительства или доверия пользователей.</p>\n' +
  '\n' +
  '<h2>Изменения Политики</h2>\n' +
  '<p>Мы оставляем за собой право вносить изменения в эту Политику в любое время. Все изменения будут опубликованы на нашем сайте, и обновленная Политика вступит в силу с ранее из нижеперечисленных дат:</p>\n' +
  '<p>Дата использования наших Услуг после публикации обновленной Политики, или</p>\n' +
  '<p>30 дней после публикации обновленной Политики на нашем сайте.</p>\n' +
  '<p>Пожалуйста, регулярно просматривайте эту Политику, чтобы быть в курсе любых обновлений.</p>\n' +
  '`\n'

export default ded
