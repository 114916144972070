<template>
  <ModalWrapper :model-name="modelName">
    <div class="add-cash-modal">
      <div class="add-cash-modal-head">
        <p class="add-cash-modal-head_title">
          {{ $t('modals.attention') }}
        </p>
        <div @click="onClose" class="add-cash-modal-head_close">
          <img src="@/assets/icons/close.svg" alt="close remove">
        </div>
      </div>
      <div class="redirecting-modal_body">
        If you were not redirected automatically, please click <a target="_blank" :href="pathUrl">here.</a>
      </div>
      <div class="add-cash-modal_footer">
        <button @click="handleCash" class="add-cash-modal_footer-btn">
          {{ $t('buttons.makeNewPayment') }}
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
import ModalWrapper from "@/components/modals/ModalWrapper.vue";

export default  {
  name: 'RedirectingModal',
  components: {
    ModalWrapper
  },
  props: {
    modelName: {
      type: String
    },
  },
  setup (props, { emit }) {
    const store = useStore()
    const pathUrl = computed(() => {
      return store.getters.getRedirectLink
    })
    const onClose = () => {
      store.commit('removeAllModal')
      emit('closeModal')
    }
    const handleCash = () => {
      store.commit('removeAllModal')
      store.commit('addModal', 'Cash')
    }

    return {
      pathUrl,
      onClose,
      handleCash
    }
  }
}
</script>

<style lang="scss">
.redirecting-modal {
  &_body {
    padding-bottom: 20px;
    font-size: 18px;
  }
}
</style>
