const ded = `<h2>サービス利用規約</h2>
<p>このサービス利用規約（以下「規約」）は、https://dressdown.ai に位置するウェブサイトおよび dressdown.ai が提供する関連サービスの利用を規定します。</p>
<p>https://dressdown.ai にアクセスすることで、これらの規約に従い、適用される法律および規制を遵守することに同意したことになります。これらの規約に同意しない場合、当ウェブサイトや dressdown.ai が提供するその他のサービスの利用またはアクセスは禁止されています。</p>
<p>私たち dressdown.ai は、独自の裁量でこれらの規約を見直し、変更する権利を留保します。その際には、このページを更新します。規約の変更は、公開された日から直ちに有効となります。</p>
<p>このサービス利用規約は、2024年4月25日に最終更新されました。</p>

<h2>会社情報</h2>
<p>ウェブサイト dressdown.ai（以下「サイト」）は、DATATEH LTD が所有および管理しています。この法人は、ここでは「会社」「私たち」「私たちの」と呼ばれ、イギリスに登録された会社です。私たちの主要な登録事務所は、以下の住所にあります：20 Wenlock Road, London, England, N1 7GU。お問い合わせは、上記の住所に書面を送付するか、support@dressdown.ai までメールでご連絡ください。</p>

<h2>サービスの説明</h2>
<p>dressdown.ai は、人工知能を活用して成人向けの画像を生成するウェブベースのプラットフォームです。私たちのサービスでは、ユーザーが年齢、体型、品質などの指定されたパラメータに基づいて画像をカスタマイズできます。生成された画像の責任は完全にユーザーにあります。また、プラットフォームでは、モデルによるコンテンツの購入も可能で、取引は信頼できる第三者の決済サービスプロバイダーを通じて安全に処理されます。ユーザーのプライバシー保護のため、ユーザーデータや生成された画像を保存または監視することはありません。</p>

<h2>使用制限</h2>
<p>このウェブサイトを使用することで、あなたは自分自身、あなたのユーザー、およびあなたが代表する他のすべての関係者に代わって以下のことを行わないことを保証します：</p>
<p>1. このウェブサイトに含まれる資料やソフトウェアを変更、コピー、派生物を作成、逆コンパイル、またはリバースエンジニアリングすること。</p>
<p>2. このウェブサイトの資料やソフトウェアから著作権やその他の所有権表示を削除すること。</p>
<p>3. 資料を他の人に転送したり、他のサーバーに「ミラーリング」すること。</p>
<p>4. このウェブサイトや関連サービスを故意または過失で、ネットワークやその他のサービス dressdown.ai が提供するサービスに対して乱用や妨害を行うこと。</p>
<p>5. このウェブサイトや関連サービスを使用して、嫌がらせ、不適切、猥褻、詐欺的、または違法な資料を送信または公開すること。</p>
<p>6. このウェブサイトや関連サービスを適用される法律や規制に違反して使用すること。</p>
<p>7. このウェブサイトを使用して無許可の広告やスパムを送信すること。</p>
<p>8. ユーザーの同意なしにユーザーデータを収集、集め、または収集すること。</p>
<p>9. このウェブサイトや関連サービスを使用して、第三者のプライバシー、知的財産権、またはその他の権利を侵害すること。</p>

<h2>知的財産</h2>
<p>このウェブサイトに含まれる資料の知的財産は、dressdown.ai に所有またはライセンスされており、適用される著作権および商標法によって保護されています。私たちは、ユーザーに対して資料を個人的かつ非商業的な一時的利用のために一部ダウンロードする許可を付与します。</p>
<p>これは、タイトルの譲渡ではなくライセンスの付与を意味します。このライセンスは、これらの制限や規約に違反した場合に自動的に終了し、dressdown.ai によっていつでも終了する可能性があります。</p>

<h2>ユーザー生成コンテンツ</h2>
<p>dressdown.ai は、ウェブサイト上でユーザーによって生成されたコンテンツの使用に関して一切の責任を負いません。私たちのサービスでは、ユーザーが年齢、体型、品質パラメータを指定して画像を生成できます。ただし、ユーザーが生成した画像については、保存、監視、または責任を負うことはありません。コンテンツを生成するユーザーが完全に所有権を保持し、その使用または配布、およびその結果として生じる可能性のあるすべての責任を負います。</p>

<h2>年齢要件とユーザーの責任</h2>
<p>ユーザーは、当ウェブサイトにアクセスし使用するために、18歳以上、またはその管轄区域における成年年齢のいずれか高い年齢を満たしている必要があります。サービスを利用することにより、これらの年齢要件を満たしていることを確認することになります。</p>
<p>ユーザーが責任を持って画像を生成することを信頼しています。明示的な同意なしに個人の画像や未成年者の画像を生成することは禁じられています。他人の権利を侵害したり、違法行為を行ったり、禁止されている行動を行うためのサービスの利用は許可されません。</p>

<h2>購入と支払いオプション</h2>
<p>dressdown.ai では、ユーザー体験を向上させるために設計された異なる支払いプランを提供しており、各プランには特定の機能とクレジットが含まれています：</p>
<p>- <strong>15 EUR</strong>: 30生成。</p>
<p>- <strong>25 EUR</strong>: 60生成。</p>
<p>- <strong>35 EUR</strong>: 90生成。</p>
<p>- <strong>45 EUR</strong>: 120生成。</p>
<p>- <strong>99 EUR</strong>: 270生成。</p>
<p>すべてのプランには、迅速な生成とHD品質の画像が含まれています。支払いは一回限りの取引として行われ、自動更新はありません。ユーザーは選択したプランの特定の機能と制限を確認するべきです。</p>

<h2>責任</h2>
<p>当ウェブサイトおよびその資料は「現状のまま」で提供されます。法律で許可される範囲内で、dressdown.ai は明示的または暗示的な保証を行わず、商業性、特定目的への適合性、知的財産権の非侵害、またはその他の権利の侵害についても一切の保証を否認し、無効とします。</p>
<p>dressdown.ai またはその供給者は、このウェブサイトやその資料の使用または使用不能から生じた、あなたや第三者による損害に対して一切の責任を負わないものとします。たとえ dressdown.ai またはその認可された代表者がそのような損害の可能性について口頭または書面で通知されていた場合でも同様です。</p>
<p>この契約の文脈では、「結果的損失」には、結果的損失、間接的損失、実際のまたは予想される利益の喪失、利益の喪失、収益の喪失、ビジネスの喪失、信用の喪失、機会の喪失、貯蓄の喪失、評判の喪失、使用の喪失、および/またはデータの損失または破損が含まれ、法令、契約、衡平法、過失（過失を含む）、免責、またはその他の方法に基づくものとします。</p>
<p>一部の管轄区域では、暗示的保証の制限や結果的または偶発的損害の制限を認めていないため、これらの制限が適用されない場合があります。</p>

<h2>未成年者を含む露骨なコンテンツに対するゼロトレランス</h2>
<p>私たちは未成年者を含む露骨なコンテンツに断固反対します。すべてのユーザーに安全な環境を提供するため、先進的な自動化システムを使用して禁止されたコンテンツを積極的に監視しています。未成年者の露骨なコンテンツを生成または利用しているアカウントは直ちに禁止され、ユーザーの国の適切な当局に報告されます。</p>

<h2>資料の正確性</h2>
<p>当ウェブサイトに掲載されている資料は、包括的ではなく、一般的な情報提供のみを目的としています。dressdown.ai は、ウェブサイト上の資料の正確性、結果の可能性、または信頼性について保証せず、またそのような資料またはリンク先のリソースに関しても一切の表明を行いません。決定を下す際には、当該資料のみに依存せず、他のより完全な情報源を参照することをお勧めします。ウェブサイトの資料に厳密に依存する責任は完全にあなたにあります。私たちは予告なしにウェブサイトの情報を変更する権利を有し、ユーザーはこれらの変更を監視する責任があります。ウェブサイト上の歴史的情報は、参考のために提供されています。</p>

<h2>追加ツール</h2>
<p>当ウェブサイトには、私たちの管理外にある第三者のツールが含まれている場合があります。これらのツールへのアクセスは「現状のまま」で提供され、保証や承認は一切ありません。私たちは、ウェブサイト上で提供される第三者のツールの使用について一切の責任を負いません。これらのツールの使用に関連するリスクは完全にあなたの責任です。関連する第三者提供者のサービス利用規約を確認することをお勧めします。dressdown.ai は今後、新しい第三者ツールを提供する権利を留保します。</p>

<h2>リンク</h2>
<p>dressdown.ai は、ウェブサイトにリンクされたすべてのサイトを確認しておらず、そのようなリンクされたサイトの内容について一切の責任を負いません。リンクの含有は、dressdown.ai がそのサイトを承認、承認、または管理していることを意味しません。リンクされたサイトの使用は自己責任で行い、これらのサイトの適合性について自分自身で調査することを強くお勧めします。</p>

<h2>補償</h2>
<p>あなたは、私たち、私たちの子会社、関連会社、およびそれぞれの役員、代理人、パートナー、従業員を守り、補償することに同意します。これには、合理的な弁護士費用および経費を含む、以下の理由で第三者から提起された損失、損害、責任、請求、または要求が含まれます：(1) あなたの貢献；(2) ウェブサイトの使用；(3) これらのサービス利用規約の違反；(4) これらのサービス利用規約に記載されたあなたの表明および保証の違反；(5) 第三者の権利、特に知的財産権の違反；または (6) ウェブサイトを通じて接続した他のユーザーに対する明らかな有害行為。前述の内容にかかわらず、私たちは、あなたが補償する義務のあるいかなる事項についても、あなたの費用で独占的に防御および管理する権利を留保し、あなたはそのような請求に対する私たちの防御に協力することに同意します。私たちは、そのような請求、訴訟、または手続きについて、認識次第、合理的に通知する努力をします。</p>

<h2>契約の終了</h2>
<p>私たちは、事前通知や責任を負うことなく、何らかの理由であなたのアカウントおよびウェブサイトおよびサービスへのアクセスを直ちに停止または終了することがあります。契約が終了した場合、サービスの利用権は直ちに終了します。</p>
<p>アカウントを終了する場合は、単にウェブサイトの使用を中止することができます。</p>
<p>契約終了後も、その性質上、終了後も存続する必要がある規約のすべての条項が存続します。これには、所有権条項、保証の免責、補償、および責任の制限が含まれます。</p>

<h2>分離可能性</h2>
<p>これらのサービス利用規約のいかなる条項が執行不可能または無効と判断された場合、その条項は、適用法の下でその目的を最大限に達成するために変更および解釈され、残りの条項は引き続き完全に有効とします。</p>

<h2>変更</h2>
<p>dressdown.ai は、独自の裁量で、これらのサービス利用規約をいつでも変更または置き換える権利を留保します。重要な変更がある場合には、新しい規約が適用される前に少なくとも30日間の通知を提供します。重要な変更が何かは、私たちの独自の裁量で判断します。</p>
<p>これらの変更が有効になった後もサービスにアクセスまたは使用し続けることで、改訂された規約に拘束されることに同意するものとします。新しい規約に同意しない場合、dressdown.ai の使用権限がなくなります。</p>

<h2>完全な契約</h2>
<p>サービス利用規約、プライバシーポリシー、および当ウェブサイトに掲示されたその他の法的通知またはポリシーは、ウェブサイトの利用に関するあなたと dressdown.ai との間の完全な契約を構成します。</p>

<h2>準拠法</h2>
<p>これらのサービス利用規約は、イギリスの法律に準拠し、解釈されます。あなたはその場所の裁判所の専属管轄に取り消し不能に服することに同意します。</p>

`

export default ded
